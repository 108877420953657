export enum AgreementUsage {
  Booking = "booking",
  Registration = "registration"
}

export interface Agreement {
  _id: string;
  name: string;
  usage: AgreementUsage;
  text: string;
  linkToPolicy: boolean;
  policyText: string;
  includePolicyInSiteFooter: boolean;
  enabled: boolean;
  client: string;
}
