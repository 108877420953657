import type { AccountCreditItem } from "types/model/account-credit";
import type { Activity, AddOn, Ticket } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { RefundOption } from "types/model/cancellation";
import type { DiscountRule } from "types/model/discount-rule";
import type { FieldData } from "types/model/field-data";
import type {
  SessionPass,
  SessionPassCreditToIssue,
  SessionPassTransaction
} from "types/model/session-pass";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import type { UserSubscription } from "types/model/user-subscription";
import type { WaitlistEntry } from "types/model/waitlistEntry";

interface PassDebitLineItemData<ALICUF = string[]> {
  creditUsage: number;
  creditUsageSetByUser?: boolean;
  activityLineItemsCreditUsedFor: ALICUF;
  sessionPass: SessionPass;
}

export interface LineItem<
  AG = ActivityGroup,
  A = Activity<string>,
  ALICUF = string[],
  B = string,
  US = UserSubscription<string, Attendee, string, SubscriptionPlan>,
  AT = Attendee
> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _id: any;
  activityGroup: AG;
  activity: A;
  activities: A[];
  ticket: Ticket;
  booking: B;
  discountRule: DiscountRule;
  discountActivityGroups: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  total: number;
  attendee: AT;
  fieldData: FieldData[];
  user: string;
  client: string;
  description?: string;
  userSubscription?: US;
  userSubscriptionAssignmentEndsAt?: Date;
  parent?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  addOn?: AddOn;
  sessionPassTransaction?: SessionPassTransaction;
  passDebitData?: PassDebitLineItemData<ALICUF>;
  accountCreditItems?: AccountCreditItem<AccountCreditItem, string, string>[];
  children: LineItem[];
  cancelled: boolean;
  type: LineItemType;
  addedFromWaitlist?: boolean;
  waitlistEntry?: WaitlistEntry;
  created: Date;
  updated: Date;
  cancelledAt?: Date;
  save: (data?: Partial<LineItem> & { timestamps: boolean }) => Promise<void>;
  deleteOne: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toObject: () => any;
}

export enum LineItemType {
  Activity = "activity",
  Fee = "fee",
  Deduction = "deduction",
  AddOn = "addOn",
  Discount = "discount",
  PassCredit = "passCredit",
  PassDebit = "passDebit",
  SubscriptionCharge = "subscriptionCharge",
  AccountCredit = "accountCredit",
  AccountDebit = "accountDebit"
}

export interface CancelLineItemsData {
  bookingId: string;
  lineItemIds: string[];
  shouldIssueRefund: boolean;
  refundAmount?: number;
  shouldIssueSessionPassCredit: boolean;
  sessionPassCreditsToIssue?: SessionPassCreditToIssue[];
}

export interface UserCancelLineItemsData {
  bookingId: string;
  activityGroupId: string;
  lineItemIds: string[];
  refundOption: RefundOption;
}
