import { isAxiosError } from "axios";
import { isString } from "lodash";
import type { ErrorResponse } from "types";

const DEFAULT_ERROR_MESSAGE =
  "An unexpected error occurred. Please try again or contact support.";

type GetErrorArgs = {
  /** The error thrown by the request. */
  error: unknown;
  /**
   * Error message if the error is an instance of `Error`. Can pass a string
   * for a static message or a function with the `Error` arg that can be used
   * in the custom error message. If not provided, the error message will be
   * `error.message` or the default error message: `"An unexpected error
   * occurred. Please try again or contact support."`
   */
  errorMessage?: ((error: Error) => string) | string;
  /**
   * Error message if the error is not an instance of `AxiosError` or `Error`.
   * If not provided, the default error message will be thrown: `"An unexpected
   * error occurred. Please try again or contact support."`
   */
  otherErrorMessage?: string;
  /**
   * Whether to log the error to the console. Defaults to `false`.
   */
  log?: boolean;
};

/**
 * Extracts an appropriate error message from three types of errors:
 * 1. Axios errors: Extracts the message from the response data.
 * 2. Standard Error instances: Uses the provided `errorMessage` or the error's
 * message property.
 * 3. Other types: Uses the provided otherErrorMessage or the `DEFAULT_ERROR_MESSAGE`.
 *
 * @param {unknown} error - The error thrown by the request.
 *
 * @param errorMessage - Error message if the error is an instance of `Error`.
 * Can pass a string for a static message or a function with the `Error` arg
 * that can be used in the custom error message. If not provided, the error
 * message will be `error.message` or the default error message: `"An
 * unexpected error occurred. Please try again or contact support."`
 *
 * @param otherErrorMessage - Default error message if the error is not an
 * instance of `AxiosError` or `Error`. If not provided, the default error
 * message will be thrown: `"An unexpected error occurred. Please try again
 * or contact support."`
 *
 * @param log - Whether to log the error to the console. Defaults to `false`.
 */
export function getErrorMessage({
  error,
  errorMessage,
  otherErrorMessage,
  log = false
}: GetErrorArgs): string {
  let message = "";
  if (isAxiosError<ErrorResponse>(error)) {
    const axiosMessage = isString(error.response?.data)
      ? error.response.data
      : error.response?.data.message;

    message = axiosMessage || DEFAULT_ERROR_MESSAGE;
  } else if (error instanceof Error) {
    if (typeof errorMessage === "function") {
      message = errorMessage(error);
    } else if (typeof errorMessage === "string") {
      message = errorMessage;
    } else {
      message = error.message || DEFAULT_ERROR_MESSAGE;
    }
  } else {
    message = otherErrorMessage || DEFAULT_ERROR_MESSAGE;
  }

  if (log) console.error(error);

  return message || DEFAULT_ERROR_MESSAGE;
}
