import type { Activity } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { Booking } from "types/model/booking";
import type { FieldFilters } from "types/model/field-data";
import type { LineItem } from "types/model/line-item";
import type { SubscriptionPlan } from "types/model/subscription-plan";

// not_started, active, completed, released, canceled (from subscription schedule)
// past_due, unpaid (from subscription). unpaid = no more attempts will be made to collect payment.

// notStarted|active|pastDue|ended|cancelled
export enum UserSubscriptionStatus {
  NotStarted = "notStarted",
  Active = "active",
  PastDue = "pastDue",
  Ended = "ended",
  Cancelled = "cancelled"
}

// draft, open, paid, uncollectible, or void
export enum SubscriptionInvoiceStatus {
  Draft = "draft",
  Open = "open",
  Paid = "paid",
  Uncollectible = "uncollectible",
  Void = "void"
}

export interface SubscriptionInvoice {
  date: Date;
  total: number;
  status: SubscriptionInvoiceStatus;
  invoiceUrl: string;
}

export interface UserSubscription<
  U = string,
  A = string,
  C = string,
  SP = string
> {
  _id: string;
  subscriptionNumber: number;
  user: U;
  attendee: A;
  lineItems: LineItem<ActivityGroup, Activity<string>, string, Booking>[];
  subscriptionPlan: SP;
  stripeSubscriptionScheduleId: string;
  stripeSubscriptionId: string;
  billingHistory: SubscriptionInvoice[];
  payments: SubscriptionPayment[];
  client: C;
  cancelled: boolean;
  cancelledAt: Date;
  defaultCardId: string;
  hasUpcomingSessionsAssigned: boolean;
  canBeCancelled: boolean;
  activityAssignments?: ActivityGroup[];
  status: UserSubscriptionStatus;
  created: Date;
  updated: Date;
  toObject: () => UserSubscription<U, A, C, SP>;
  save: () => Promise<UserSubscription<U, A, C, SP>>;
}

export enum SubscriptionPaymentStatus {
  Pending = "pending",
  Completed = "completed",
  Failed = "failed",
  Voided = "voided"
}

export interface SubscriptionPaymentTransaction {
  _id?: string;
  transactionType: SubscriptionPaymentTransactionType;
  stripePaymentIntentId?: string;
  stripeRefundId?: string;
  amount: number;
  created: Date;
}

export interface SubscriptionPayment {
  _id?: any;
  stripeInvoiceId: string;
  stripeInvoiceNumber?: string;
  status: SubscriptionPaymentStatus;
  invoiceTotal: number;
  invoiceUrl?: string;
  transactions: SubscriptionPaymentTransaction[];
  initialPayment?: boolean;
  completedAt?: Date;
  voidedAt?: Date;
  created?: Date;
  updated?: Date;
}

export interface UserSubscriptionAssignmentData {
  userSubscriptionIds: string[];
  activityGroupId: string;
  shouldAssignAllResults: boolean;
  filters: FieldFilters;
  shouldEmailUsers: boolean;
}

export interface CancelUserSubscriptionData {
  userSubscriptionId: string;
  cancelHandling: UserSubscriptionCancelHandlingOption;
}

export interface CurrentUserCancelUserSubscriptionData {
  userSubscriptionId: string;
}

export interface UserSubscriptionUnassignActivitiesData {
  userSubscriptionId: string;
  lineItemIds: string[];
}

export enum UserSubscriptionCancelHandlingOption {
  Immediate = "immediate",
  EndOfPeriod = "endOfPeriod",
  None = "none"
}

export type UserSubscriptionLineItem = LineItem<
  ActivityGroup,
  Activity<string>,
  string,
  Booking<string, string>,
  UserSubscription<string, Attendee, string, SubscriptionPlan>
>;

export enum UserSubscriptionLineItemStatus {
  Upcoming = "upcoming",
  InProgress = "inProgress",
  Ended = "ended"
}

export enum SubscriptionPaymentTransactionType {
  Charge = "charge",
  Refund = "refund"
}
