import axios from "axios";
import type { MutationResultPair } from "react-query";
import { useMutation } from "react-query";
import type { ErrorResponse } from "types";
import { getErrorMessage } from "utils/getErrorMessage";

interface SendVerificationEmailResponse {
  data: {
    success: boolean;
    email: string;
  };
}

export default function useSendVerificationEmail(): MutationResultPair<
  SendVerificationEmailResponse,
  ErrorResponse,
  void,
  unknown
> {
  return useMutation(async () => {
    try {
      return await axios.post("/api/user/send-verification-email");
    } catch (error: unknown) {
      throw new Error(
        getErrorMessage({
          error,
          errorMessage: error => `Failed to send email: ${error.message}`,
          otherErrorMessage: "Failed to send email."
        })
      );
    }
  });
}
