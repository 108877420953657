import type { FieldUsage } from "types/constants";
import type { FieldOption } from "types/model/field-option";

export interface Field<AGs = string[]> {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  type: FieldType;
  title: string;
  fieldOptions?: FieldOption[];
  usage: FieldUsage;
  appliesToAllActivityGroups: boolean;
  activityGroups?: AGs;
  internalKey: string;
  created: string;
  enabled: boolean;
  helpText: string;
  textareaFormatting: TextAreaFormatting;
  maxFiles?: number;
  uploadType?: UploadType;
  useForAdminFiltering: boolean;
  adminColumnVisible: boolean;
  useForSiteFiltering: boolean;
  registerColumnVisible: boolean;
  sessionsBookedReportColumnVisible?: boolean;
  sessionsBookedReportFilterVisible?: boolean;
  sessionsBookedExportColumnVisible?: boolean;
  activitySalesReportColumnVisible?: boolean;
  activitySalesReportFilterVisible?: boolean;
  activitySalesReportExportColumnVisible?: boolean;
  attendanceReportColumnVisible?: boolean;
  attendanceReportFilterVisible?: boolean;
  attendanceReportExportColumnVisible?: boolean;
  activitySalesByActivityGroupReportColumnVisible?: boolean;
  activitySalesByActivityGroupReportExportColumnVisible?: boolean;
  userSubscriptionsListFilterVisible?: boolean;
  bookingsExportColumnVisible?: boolean;
  lineItemsExportColumnVisible?: boolean;
  adminOnly: boolean;
  required: boolean;
  updated: string;
  client: string;
  weight: number;
  deleteOne: () => Promise<void>;
  save: () => Promise<void>;
}

export enum FieldType {
  Text = "text",
  Textarea = "textarea",
  Email = "email",
  Currency = "currency",
  Number = "number",
  Integer = "integer",
  SelectList = "selectList",
  CheckboxSingle = "checkboxSingle",
  CheckboxMultiple = "checkboxMultiple",
  Venue = "venue",
  Date = "date",
  FileUpload = "fileUpload"
}

export enum TextAreaFormatting {
  PlainText = "plainText",
  RichText = "richText"
}

export enum UploadType {
  Image = "image",
  Document = "document"
}

export interface FieldVisibilityHash {
  [key: string]: boolean;
}
