import { formatDate } from "helpers/date";
import {
  formatCurrency,
  getBadgeColorFromBookingStatus,
  getBookingStatusReadableName
} from "helpers/helpers";
import dynamic from "next/dynamic";
import React from "react";
import type { Attendee } from "types/model/attendee";
import type {
  BookingItemByActivityGroup,
  UserBooking
} from "types/model/basket";
import type { AddOnBookingAddOnItem, Booking } from "types/model/booking";
import type { Client } from "types/model/client";
import type { LineItem } from "types/model/line-item";
import { LineItemType } from "types/model/line-item";
import type { TableColumn, TableData } from "types/model/table";

const Badge = dynamic(() => import("components/Badge"), {
  ssr: true,
  loading: () => null
});

export const getLineItemTotalWithAddOns = (lineItem: LineItem): number => {
  const total = lineItem.children.reduce((acc, addOnLineItem) => {
    if (!lineItem.cancelled && addOnLineItem.cancelled) {
      return acc;
    }
    return (acc += addOnLineItem.total);
  }, lineItem.total);

  return total;
};

export const getAttendeesFromAddOnItems = (
  addOnItems: AddOnBookingAddOnItem<LineItem>[]
): Attendee[] => {
  const attendees = addOnItems
    .filter(addOnItem => addOnItem.addOn.price > 0)
    .reduce((acc: Attendee[], addOnItem) => {
      const attendee = addOnItem.parent.attendee;

      const isAlreadyAdded = acc.some(
        existingAttendee => existingAttendee._id === attendee._id
      );

      if (!isAlreadyAdded) {
        acc.push(attendee);
      }

      return acc;
    }, []);

  return attendees;
};

export const getAreAllLineItemsAssignedAttendee = (
  lineItems: LineItem[]
): boolean => {
  return lineItems
    .filter(lineItem => lineItem.type === LineItemType.Activity)
    .every(lineItem => lineItem.attendee);
};

export const getLineItemReadableName = (lineItem: LineItem): string => {
  switch (lineItem.type) {
    case LineItemType.Activity:
      return "Activity";
    case LineItemType.Fee:
      return "Fee";
    case LineItemType.Deduction:
      return "Deduction";
    case LineItemType.AddOn:
      return "Add-on";
    case LineItemType.Discount:
      return "Discount";
    case LineItemType.PassCredit:
      return "Pass Credit";
    case LineItemType.PassDebit:
      return "Pass Debit";
    case LineItemType.AccountCredit:
      return "Account credit";
    case LineItemType.AccountDebit:
      return "Account debit";

    default:
      return "";
  }
};

export const getUserBookingColumnHeaders = (): TableColumn[] => {
  const columnHeaders = [
    {
      Header: "Booking",
      accessor: "bookingNumber"
    },

    {
      Header: "Created",
      accessor: "created"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Total",
      accessor: "total"
    },
    {
      Header: "Balance",
      accessor: "balance"
    }
  ];

  return columnHeaders;
};

interface GetUserBookingsTableData {
  data: Booking[];
  client: Client;
}

export const getUserBookingsTableData = ({
  data,
  client
}: GetUserBookingsTableData): TableData => {
  const formattedData = data.map(booking => {
    return {
      _id: booking._id,
      bookingNumber: {
        type: "html",
        value: `<a class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline cursor-pointer">#${booking.bookingNumber}</a>`,
        clickAction: "goToBooking"
      },
      created: formatDate(
        booking.created,
        "d MMM yyyy, h.mmaaaa",
        client.timeZone
      )
        .replace("a.m.", "am")
        .replace("p.m.", "pm"),
      status: React.createElement(
        Badge,
        { color: getBadgeColorFromBookingStatus(booking.status) },
        getBookingStatusReadableName(booking.status)
      ),
      total: formatCurrency({
        rawAmount: booking.total || 0,
        currency: client.currency
      }),
      balance: formatCurrency({
        rawAmount: booking.balance || 0,
        currency: client.currency
      }),
      enabled: true // prevent lower opacity for disabled rows
    };
  });

  return formattedData;
};

export const getActivityGroupItemFromUserBooking = (
  userBooking: UserBooking,
  activityGroupId: string
): BookingItemByActivityGroup => {
  const activityGroupItem = userBooking.activityGroupItems.find(
    activityGroupItem => activityGroupItem.activityGroup._id === activityGroupId
  );

  return activityGroupItem as BookingItemByActivityGroup;
};
