import { isAfter } from "date-fns";
import {
  getActivitiesToChargeSubscriptionCoverFeesFor,
  getIsActivitySessionAvailableToPurchase
} from "helpers/activity";
import { getStartOfNextMonth } from "helpers/date";
import { formatCurrency, renderActivityDateString } from "helpers/helpers";
import type { Hash } from "types/general";
import type { AddOn, Ticket } from "types/model/activity";
import { TicketType } from "types/model/activity";
import type {
  ActivityGroup,
  ActivityGroupSalesData,
  ActivityGroupWithPastActivityIds
} from "types/model/activity-group";
import type { Client } from "types/model/client";

export const getDoesTicketHaveSales = (
  ticket: Ticket,
  salesData: ActivityGroupSalesData
): boolean => {
  let hasTicketSales = false;
  if (ticket.type === TicketType.Single) {
    hasTicketSales =
      Boolean(salesData?.singleSessionTicketSales[ticket._id]) &&
      Object.values(salesData.singleSessionTicketSales[ticket._id]).some(
        sales => sales > 0
      );
  } else if (ticket.type === TicketType.Subscription) {
    hasTicketSales = salesData?.subscriptionTicketSales[ticket._id] > 0;
  } else if (ticket.type === TicketType.All) {
    hasTicketSales = salesData?.allSessionTicketSales[ticket._id] > 0;
  }

  return hasTicketSales;
};

export const getAllSessionTicketPrice = (
  ticket: Ticket,
  activityGroup: ActivityGroup,
  client: Client
): number => {
  if (ticket.shouldProRata) {
    const allEnabledActivitySessions = activityGroup.activities.filter(
      activity => activity.enabled
    );

    const remainingActivitySessionsAvailableToPurchase =
      activityGroup.activities.filter(activity =>
        getIsActivitySessionAvailableToPurchase(activity, activityGroup, client)
      );

    const proportionRemaining =
      remainingActivitySessionsAvailableToPurchase.length /
      allEnabledActivitySessions.length;

    const proRataPrice = Math.round(ticket.price * proportionRemaining);

    return proRataPrice;
  } else {
    return ticket.price;
  }
};

export const getSubscriptionTicketPrice = (
  ticket: Ticket,
  activityGroup: ActivityGroup,
  client: Client
) => {
  if (!ticket.subscriptionPlan?.name) {
    throw new Error("No subscription plan document attached");
  }

  if (ticket?.allowMidMonthBookings && ticket?.coverFeePerSession) {
    const activitiesToChargeSubscriptionCoverFeesFor =
      getActivitiesToChargeSubscriptionCoverFeesFor(activityGroup, client);

    return (
      ticket?.coverFeePerSession *
      activitiesToChargeSubscriptionCoverFeesFor.length
    );
  }

  return 0;
};

export const getSubscriptionTicketPriceFormatted = (
  ticket: Ticket,
  activityGroup: ActivityGroup,
  client: Client
) => {
  const price = getSubscriptionTicketPrice(ticket, activityGroup, client);

  const priceFormatted = formatCurrency({
    rawAmount: price,
    currency: client.currency
  });

  return priceFormatted;
};

export const getSessionsInfoForAllOrSubscriptionsTicket = (
  activityGroup: ActivityGroupWithPastActivityIds,
  ticket: Ticket,
  client: Client
) => {
  const allowMidMonthBookings =
    ticket.type === TicketType.All ||
    (ticket.type === TicketType.Subscription && ticket.allowMidMonthBookings);
  const startOfNextMonth = getStartOfNextMonth(client.timeZone);

  const ticketActivities = activityGroup.activities.filter(
    activity =>
      !activityGroup.activitiesPastIds.includes(activity._id) &&
      (allowMidMonthBookings ||
        isAfter(new Date(activity.date.start), startOfNextMonth))
  );

  if (ticketActivities.length === 0) {
    // shouldn't happen
    return null;
  }

  if (ticketActivities.length == activityGroup.activities.length) {
    return "All sessions";
  }

  if (ticketActivities.length === 1) {
    const activityDateFormatted = renderActivityDateString({
      activityDate: ticketActivities[0].date,
      dateOnly: true,
      timeOnly: false,
      timeZone: client.timeZone,
      includeYear: false
    });

    return `All sessions (${activityDateFormatted} only)`;
  }

  const firstActivity = ticketActivities[0];
  const startingDateFormatted = renderActivityDateString({
    activityDate: firstActivity.date,
    dateOnly: true,
    timeOnly: false,
    timeZone: client.timeZone,
    includeYear: false
  });

  return `All sessions (from ${startingDateFormatted})`;
};

export const getDoesSubscriptionTicketHaveSessionsRemaining = (
  activityGroup: ActivityGroupWithPastActivityIds,
  ticket: Ticket,
  client: Client
) => {
  const allowMidMonthBookings =
    ticket.type === TicketType.All ||
    (ticket.type === TicketType.Subscription && ticket.allowMidMonthBookings);
  const startOfNextMonth = getStartOfNextMonth(client.timeZone);

  const ticketActivities = activityGroup.activities.filter(
    activity =>
      !activityGroup.activitiesPastIds.includes(activity._id) &&
      (allowMidMonthBookings ||
        isAfter(new Date(activity.date.start), startOfNextMonth))
  );

  return ticketActivities.length > 0;
};

export const getSessionsCanBeUsedForFromActivityIdsMap = (
  ticket: Ticket,
  newActivityIdsMap: Hash<string>,
  existingActivityIds: string[]
) => {
  const sessionsCanBeUsedFor =
    ticket.type === TicketType.Single && ticket.restrictSessions
      ? ticket.sessionsCanBeUsedFor?.reduce((arr, activityId) => {
          if (activityId.startsWith("temp_")) {
            if (newActivityIdsMap[activityId]) {
              arr.push(newActivityIdsMap[activityId]);
            }
          } else if (existingActivityIds.includes(activityId)) {
            arr.push(activityId);
          }
          return arr;
        }, [])
      : [];

  return sessionsCanBeUsedFor;
};

export const getAddOnSessionsCanBeUsedForFromActivityIdsMap = (
  addOn: AddOn,
  newActivityIdsMap: Hash<string>,
  existingActivityIds: string[]
) => {
  const sessionsCanBeUsedFor = addOn.restrictSessions
    ? addOn.sessionsCanBeUsedFor?.reduce((arr, activityId) => {
        if (activityId.startsWith("temp_")) {
          if (newActivityIdsMap[activityId]) {
            arr.push(newActivityIdsMap[activityId]);
          }
        } else if (existingActivityIds.includes(activityId)) {
          arr.push(activityId);
        }
        return arr;
      }, [])
    : [];

  return sessionsCanBeUsedFor;
};

export const getCanAddOnBeUsedForActivity = (
  addOn: AddOn,
  activityId: string
) => {
  return (
    !addOn.restrictSessions || addOn.sessionsCanBeUsedFor?.includes(activityId)
  );
};

export const getCanSomeAddsOnBeUsedForActivity = (
  addOns: AddOn[],
  activityId: string
) => {
  const canSomeBeUsed = addOns.some(addOn =>
    getCanAddOnBeUsedForActivity(addOn, activityId)
  );
  return canSomeBeUsed;
};

export const getDoesAddOnsHashContainItemForActivity = (
  existingAddOnIdsHash: Hash,
  activityId: string
) => {
  const addOnIds = Object.keys(existingAddOnIdsHash);
  return addOnIds.some(addOnId => addOnId.startsWith(`${activityId}_`));
};

export const getDoesAddOnsHashContainItemForLineItemAndActivity = (
  existingAddOnIdsHash: Hash,
  lineItemId: string,
  activityId: string
) => {
  const addOnIds = Object.keys(existingAddOnIdsHash);
  return addOnIds.some(addOnId =>
    addOnId.startsWith(`${lineItemId}_${activityId}_`)
  );
};
