import { Currency } from "types/model/client";

const zeroDecimalCurrencies = [Currency.JPY];

export const getIsCurrencyZeroDecimal = (currency: Currency): boolean => {
  return zeroDecimalCurrencies.includes(currency);
};

export const convertAmountBasedOnCurrency = (
  amount: string | number,
  currency: Currency
) => {
  const isZeroBasedCurrency = getIsCurrencyZeroDecimal(currency);
  const parsedAmount = Number(amount);
  return isZeroBasedCurrency
    ? Math.round(parsedAmount)
    : Math.round(parsedAmount * 100);
};

export const getCurrencyAmountForDisplay = (
  amount: number,
  currency: Currency
) => {
  const isZeroBasedCurrency = getIsCurrencyZeroDecimal(currency);
  return isZeroBasedCurrency ? amount : amount / 100;
};
