import type { Field } from "types/model/field";
import type { Venue } from "types/model/venue";

export interface FieldData {
  dataType: string; // TODO
  field: Field | any;
  value?: any; // TODO
  valueRef?: any; // TODO
  valueRefVenue?: Venue;
  valueRefArray?: any[]; // TODO
}

export enum AccessFilterDataType {
  RefArray = "refArray",
  VenueRefArray = "venueRefArray"
}

export interface AdminActivityGroupAccessFiltersFieldData {
  dataType: AccessFilterDataType;
  field: string;
  valueRefArray?: string[];
  valueRefVenueArray?: string[];
}

export interface FieldFilters {
  [key: string]: string;
}
