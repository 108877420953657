export enum DateDuration {
  Years = "years",
  Months = "months",
  Weeks = "weeks",
  Days = "days",
  Hours = "hours",
  Minutes = "minutes",
  Seconds = "seconds"
}

export interface DateRangeStringifiedValues {
  from?: string;
  to?: string;
}
